<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
      >
        <e-columns>
          <e-column
            type='checkbox' width='50'
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="shorttag"
            headerText="Kürzel"
            textAlign="Left"
          ></e-column>
          <e-column
            field="label"
            headerText="Bezeichnung"
            textAlign="Left"
            :customAttributes="labelColumnAttributes"
          ></e-column>
          <e-column
            field="description"
            headerText="Beschreibung"
            textAlign="Left"
          ></e-column>
          <e-column
            field="incidental"
            headerText="Vorkosten"
            textAlign="Left"
          ></e-column>
          <e-column
            field=""
            headerText=""
            textAlign="Right"
            :allowSorting="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Finishing -->
    <mdb-modal
      position="bottom"
      direction="bottom"
      size="fluid"
      full-height
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title v-if="modalEdit">Nebenkosten bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Nebenkosten hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="6" class="mb-3" style="border-right: 1px solid #aaa;">
              <mdb-row>
                <mdb-col md="6">
                  <legend><mdb-icon icon="cube" class="cyan-text" /> Allgemein</legend>
                </mdb-col>
                <mdb-col md="6">
                  <mdb-btn @click.native="showEditor = true" size="sm" outline="info" class="float-right"><mdb-icon icon="edit" fas/> Beschreibung</mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col class="mb-3">
                  <input type="hidden" v-model="model.id">
                  <mdb-input
                    label="Bezeichnung"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.label"
                    required
                  />
                  <mdb-input
                    label="Kürzel"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.shorttag"
                    required
                  />
                  <legend class="mt-4">
                    <mdb-icon icon="cash-register" class="cyan-text" /> Kostenart
                  </legend>
                  <mdb-input class="pl-0" type="radio" id="price-type-1" name="price-type" radioValue="staffelung" v-model="model.price_type" @input="handlePriceTypeChange" label="Staffelung" />
                  <mdb-input class="pl-0" type="radio" id="price-type-2" name="price-type" radioValue="einmalkosten" v-model="model.price_type" @input="handlePriceTypeChange" label="Einmalkosten" />
                  <legend class="mt-4"><mdb-icon icon="cog" fas class="cyan-text" /> Vorkosten</legend>
                  <mdb-select
                    label="Vorkosten"
                    v-model="model.incidental"
                    ref="incidental"
                    validation
                    multiple
                    selectAll
                    search
                  />
                </mdb-col>
              </mdb-row>
            </mdb-col>
            <mdb-col md="6">
              <legend>
                <mdb-icon icon="list-ul" class="cyan-text" /> Preise
              </legend>
              <!--<mdb-input
                label="Preise per x Stück"
                type="number"
                class="mb-3 mt-3"
                v-show="model.price_type == 'staffelung'"
                v-model="model.price_unit"
                required
              />-->
              <mdb-btn tag="a" color="info" floating size="sm" v-show="model.price_type == 'staffelung'" @click="priceTableAdd()"><mdb-icon icon="plus"/></mdb-btn>
              <b-editable-table
                bordered
                class="editable-table"
                v-model="model.price"
                :rowUpdate="priceTableRowUpdate"
                :editMode="'cell'"
                :fields="priceTableFields"
              >
                <template #cell(delete)="data">
                  <mdb-btn tag="a" color="red" floating size="sm" @click="priceTableDelete(data)"><mdb-icon icon="times"/></mdb-btn>
                </template>
              </b-editable-table>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" v-if="modalEdit">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern
        </mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" v-else>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Hinzufügen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Finishing -->
    <!-- Modal: Editor -->
    <mdb-modal
      centered
      removeBackdrop
      size="lg"
      direction="bottom"
      :show="showEditor"
      @close="closeEditor"
    >
      <mdb-modal-header>
        <mdb-modal-title>Beschreibung bearbeiten</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col md="12" class="mb-3">
            <mdb-row>
              <mdb-col class="mb-3">
                <vue-editor
                  id="descriptionEditor"
                  v-model="model.description"
                  :editor-toolbar="editorToolbar"
                />
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click.native="showEditor = false">Schließen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Editor -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Nebenkosten löschen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Nebenkosten wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.label">
                      {{ record.label }}
                    </li>
                    <br>
                    <p v-if="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="danger" :disabled="loading === true" @click.native="deleteEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Löschen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: importRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showImportRecords"
      @close="showImportRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Nebenkosten importieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-import" fas size="4x" class="cyan-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Datei (Excel oder CSV) aus die importiert werden soll:
            </p>
            <input type="file" @change="handleFileUpload( $event )"/>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="info" @click="showImportRecords = false">Abbrechen</mdb-btn>
        <!--<mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" @click.native="importRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Importieren
        </mdb-btn>-->
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="true" @click.native="importRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Importieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: importRecords -->
    <!-- Modal: exportRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showExportRecords"
      @shown="exportRecordsShown"
      @close="showExportRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Nebenkosten exportieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-export" fas size="4x" class="cyan-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen Sie die Art der Nebenkosten aus, die in eine Excel-Datei exportiert werden soll:
            </p>
            <mdb-input type="radio" id="export-option-1" name="exportOptions" radioValue="all" v-model="action.export" class="mb-2" label="Alle Nebenkosten" />
            <mdb-input type="radio" id="export-option-2" name="exportOptions" radioValue="template" v-model="action.export" class="mb-2" label="Vorlage für Import" />
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <div class="mb-2 pl-4">Ausgewählte Nebenkosten:</div>
                  <p v-show="this.action.export === 'all'" class="pl-4">{{ exportRecordCount }} Produkt(e)</p>
                  <p v-show="this.action.export === 'template'" class="pl-4">Vorlage für Import</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="info" @click="showExportRecords = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" @click.native="exportRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Exportieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: exportRecords -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search, Edit } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbSelect
} from 'mdbvue'
import { VueEditor } from "vue2-editor"
import BEditableTable from 'bootstrap-vue-editable-table'

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'Finishings',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbSelect,
    VueEditor,
    BEditableTable
  },
  data () {
    return {
      loading: false,
      model: {
        id: 'new',
        shorttag: '',
        label: '',
        description: '',
        incidental: [],
        price_type: 'staffelung',
        price_unit: 1,
        price: [],
        price_temp: [
          { id: 1, quantity: '-', price_industry: '', price_trade: '' }
        ]
      },
      action: {
        selection: [],
        category: 'finishings',
        import: 'gww',
        export: 'all'
      },
      priceTypeBugfix: true,
      priceTableFields: [
        { key: 'quantity', label: 'Staffelmenge', type: 'text', editable: true, placeholder: '', class: 'quarter-col' },
        { key: 'price_industry', label: 'Industriepreis', type: 'text', editable: true, placeholder: '', class: 'quarter-col' },
        { key: 'price_trade', label: 'Handelspreis', type: 'text', editable: true, placeholder: '', class: 'quarter-col' },
        { key: 'delete', label: '', class: 'quarter-col' }
      ],
      priceTableFieldsStaffelung: [
        { key: 'quantity', label: 'Staffelmenge', type: 'text', editable: true, placeholder: '', class: 'quarter-col' },
        { key: 'price_industry', label: 'Industriepreis', type: 'text', editable: true, placeholder: '', class: 'quarter-col' },
        { key: 'price_trade', label: 'Handelspreis', type: 'text', editable: true, placeholder: '', class: 'quarter-col' },
        { key: 'delete', label: '', class: 'quarter-col' }
      ],
      priceTableFieldsEinmalkosten: [
        { key: 'quantity', label: 'Staffelmenge', type: 'text', editable: false, placeholder: '', class: 'quarter-col' },
        { key: 'price_industry', label: 'Industriepreis', type: 'text', editable: true, placeholder: '', class: 'half-col' },
        { key: 'price_trade', label: 'Handelspreis', type: 'text', editable: true, placeholder: '', class: 'half-col' }
      ],
      priceTableRowUpdate: {},
      selectedRecords: [],
      exportRecordCount: 0,
      showModal: false,
      showEditor: false,
      modalEdit: false,
      showDelete: false,
      showImportRecords: false,
      showExportRecords: false,
      deleteList: [],
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_finishings.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Nebenkosten hinzufügen', prefixIcon: 'e-add', id: 'addRecord' },
        { text: 'Bearbeiten', tooltipText: 'Nebenkosten bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Löschen', tooltipText: 'Nebenkosten löschen', prefixIcon: 'e-delete-4', id: 'deleteRecord' },
        { type: 'separator' },
        { text: 'Import', tooltipText: 'Nebenkosten importieren', prefixIcon: 'e-icon-upload', id: 'importRecords' },
        // { text: 'Export', tooltipText: 'Nebenkosten exportieren', prefixIcon: 'e-icon-export', id: 'exportRecords' },
        'Search'
      ],
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      sortSettings: {
        columns: [{
          field: 'label',
          direction: 'Ascending'
        }]
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      }
    }
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    });
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = '';
    });
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
  },
  computed: {
    price_type() {
      return this.model.price_type;
    },
    exportAction() {
      return this.action.export;
    }
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
        }
      },
      deep: true
    },
    price_type() {
      if (this.model.price_type == 'staffelung') {
        this.priceTableFields = this.priceTableFieldsStaffelung
      } else if (this.model.price_type == 'einmalkosten') {
        this.priceTableFields = this.priceTableFieldsEinmalkosten
      }
    },
    exportAction: {
      handler: function () {
        axios.get('get_export_count.php?category=' + this.action.category + '&action=' + this.action.export).then(response => {
          this.exportRecordCount = response.data
        })
      }
    }
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['shorttag', 'label', 'description', 'incidental'])
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'incidental') {
        if (args.data['incidental'] === true) {
          args.cell.classList.add('status-circle', 'status-green')
        } else {
          args.cell.classList.add('status-circle', 'status-red')
        }
        args.cell.textContent = ''
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addRecord') {
        this.showModal = true
        this.modalEdit = false
      }
      if (args.item.id === 'editRecord') {
        axios.get('get_finishing.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'deleteRecord') {
        this.deleteList = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.deleteList.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
      if (args.item.id === 'importRecords') {
        this.showImportRecords = true
      }
      if (args.item.id === 'exportRecords') {
        this.showExportRecords = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_finishing.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    priceTableAdd() {
      this.priceTableRowUpdate = {
        edit: true,
        id: Date.now(),
        action: 'add',
        data: {
          id: Date.now(),
          quantity: '',
          price_industry: '',
          price_trade: ''
        }
      }
    },
    priceTableDelete(data) {
      this.priceTableRowUpdate = { id: data.id, action: 'delete' }
    },
    handlePriceTypeChange() {
      if (this.priceTypeBugfix === true) {
        var temp = this.model.price
        this.model.price = this.model.price_temp
        this.model.price_temp = temp
        this.priceTypeBugfix = false
      } else {
        this.priceTypeBugfix = true
      }
    },
    closeModal () {
      this.showModal = false
      this.showEditor = false
      this.resetModel()
      //this.$refs.grid.clearRowSelection()
    },
    closeEditor () {
      this.showEditor = false
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.model.shorttag !== '' && this.model.label !== '') {
        this.loading = true
        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_finishing.php'
        } else {
          url = 'add_finishing.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          this.loading = false
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            if (this.modalEdit === true) {
              var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
              this.$refs.grid.updateRow(rowIndex, this.model)
            } else {
              this.$refs.grid.refresh()
            }
            this.showModal = false
            this.resetModel()
            //this.$refs.grid.clearRowSelection()
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    deleteEntry () {
      this.loading = true
      var url = 'delete_finishing.php'
      axios({
        method: 'post',
        url: url,
        data: this.deleteList
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    handleFileUpload( event ) {
      this.file = event.target.files[0]
    },
    importRecords () {
      this.loading = true
      let formData = new FormData()
      formData.append('file', this.file)
      axios.post( 'import_finishings.php?action=' + this.action.import,
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showImportRecords = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    exportRecordsShown () {
      axios.get('get_export_count.php?category=' + this.action.category + '&action=' + this.action.export).then(response => {
        this.exportRecordCount = response.data
      })
    },
    exportRecords () {
      var url = Vue.prototype.$serverUrl + 'api/export_finishings.php?action=' + this.action.export
      window.open(url)
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.shorttag = ''
      this.model.label = ''
      this.model.description = ''
      this.model.incidental = []
      axios.get('list_incidentals.php').then(response => {
        var self = this
        response.data.result.forEach(function(incidental) {
          var row = { text: '(' + incidental.shorttag + ') ' + incidental.label, value: incidental.id, selected: false }
          self.model.incidental.push(row);
        })
      })
      this.model.price_type = 'staffelung'
      this.model.price_unit = 1
      this.model.price = []
      this.model.price_temp = [
        { id: 1, quantity: '-', price_industry: '', price_trade: '' }
      ]
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort, Edit]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.label-column {
  font-weight: normal;
  font-size: 16px;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

.price-data .table-add {
  float: left !important;
  font-size: 14px;
  margin-bottom: 0px !important;
}

.price-data tr td:nth-child(4), .price-data tr th:nth-child(4) {
  display: none;
}

.product-image {
  width: auto;
  max-width: 200px;
  max-height: 150px;
}

.image-column {
  width: 200px;
}

#descriptionEditor {
  height: 400px;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

table.editable-table {
  margin: auto;
  width: 100% !important;
}

table.editable-table td {
  vertical-align: middle;
}

.editable-table .data-cell {
  padding: 8px;
  vertical-align: middle;
}

table.editable-table .quarter-col {
  
}

table.editable-table .half-col {
  
}
</style>

<template>
  <mdb-container fluid>
    <!-- Section: Grid -->
    <section style="height: calc(100vh - 117px);">
      <ejs-grid
        ref="grid"
        locale="de"
        height="100%"
        :dataSource="dataSource"
        :dataBound="dataBound"
        :toolbar="toolbar"
        :toolbarClick="clickToolbar"
        :allowSorting="true"
        :sortSettings="sortSettings"
        :selectionSettings="selectionOptions"
        :rowSelected="onRowSelected"
        :rowDeselected="onRowDeselected"
        :recordDoubleClick="recordDoubleClick"
        :editSettings="editSettings"
        :queryCellInfo="queryCellInfo"
      >
        <e-columns>
          <e-column
            type='checkbox'
            width='50'
          ></e-column>
          <e-column
            field="id"
            headerText="ID"
            textAlign="Left"
            :isPrimaryKey="true"
            :visible="false"
          ></e-column>
          <e-column
            field="category_1"
            headerText="Linie"
            textAlign="Left"
          ></e-column>
          <!--<e-column
            field="category_2"
            headerText="2. Kategorie"
            textAlign="Left"
          ></e-column>-->
          <e-column
            field="item_root"
            headerText="Stammnummer"
            textAlign="Left"
          ></e-column>
          <e-column
            field="item_number"
            headerText="Artikelnummer"
            textAlign="Left"
          ></e-column>
          <e-column
            field="item_variant"
            headerText="Variante"
            textAlign="Left"
          ></e-column>
          <e-column
            field="label"
            headerText="Bezeichnung"
            textAlign="Left"
            :customAttributes="labelColumnAttributes"
          ></e-column>
          <e-column
            field="description"
            headerText="Beschreibung"
            textAlign="Left"
          ></e-column>
          <e-column
            field="image"
            headerText="Bild"
            textAlign="Left"
            width="200"
          >
          </e-column>
          <e-column
            field="finishing"
            headerText="Nebenkosten"
            textAlign="Left"
            width="200"
          ></e-column>
          <!--<e-column
            field="published"
            headerText="Veröffentlicht"
            textAlign="Left"
            width="200"
          ></e-column>-->
          <!--<e-column
            field="modified"
            headerText="Zuletzt verändert"
            textAlign="Left"
            width="200"
            format="dd.MM.yyyy HH:mm"
            type="date"
          ></e-column>-->
          <e-column
            field=""
            headerText=""
            textAlign="Right"
            :allowSorting="false"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </section>
    <!-- /Section: Grid -->
    <!-- Modal: Product -->
    <mdb-modal
      position="bottom"
      direction="bottom"
      size="fluid"
      full-height
      scrollable
      removeBackdrop
      :show="showModal"
      @close="closeModal"
    >
      <mdb-modal-header>
        <mdb-modal-title v-if="modalEdit">Produkt bearbeiten</mdb-modal-title>
        <mdb-modal-title v-else>Produkt hinzufügen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="modal-form" novalidate @submit.prevent="saveModal" @keydown.enter.prevent>
          <mdb-row>
            <mdb-col md="5" class="mb-3" style="border-right: 1px solid #aaa;">
              <mdb-row>
                <mdb-col md="6">
                  <legend><mdb-icon icon="cube" class="cyan-text" /> Allgemein</legend>
                </mdb-col>
                <mdb-col md="6">
                  <mdb-btn @click.native="showDescriptionEditor = true" size="sm" outline="info" class="float-right"><mdb-icon icon="edit" fas/> Produktbeschreibung</mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col class="mb-3">
                  <input type="hidden" v-model="model.id">
                  <mdb-select
                    label="Linie"
                    class="mb-3 mt-3"
                    v-model="model.category_1"
                    ref="category_1"
                    validation
                    :visibleOptions="6"
                  />
                  <mdb-input
                    label="Bezeichnung"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.label"
                    required
                  />
                  <mdb-input
                    label="Stammnummer"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.item_root"
                  />
                  <mdb-input
                    label="Artikelnummer"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.item_number"
                    required
                  />
                  <mdb-input
                    label="Variante"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.item_variant"
                  />
                  <!--<mdb-select
                    label="2. Kategorie"
                    class="mt-3"
                    style="position: relative; top: -8px;"
                    v-model="model.category_2"
                    ref="category_2"
                    validation
                    search
                  />-->
                </mdb-col>
              </mdb-row>
              <mdb-row class="mt-4">
                <mdb-col md="6">
                  <legend><mdb-icon icon="list" fas class="cyan-text" /> Eigenschaften</legend>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col class="mb-3">
                  <input type="hidden" v-model="model.id">
                  <mdb-input
                    label="Maße"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.dimensions"
                  />
                  <mdb-input
                    label="Gewicht"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.weight"
                  />
                  <mdb-input
                    label="Material"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.material"
                  />
                  <mdb-input
                    label="Max. Werbefläche"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.max_advertising_space"
                  />
                  <mdb-input
                    label="Katalogseite"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.catalog_page"
                  />
                  <mdb-input
                    label="Ursprungsland"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.country_of_origin"
                  />
                  <mdb-input
                    label="Zolltarifnummer"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.customs_tariff_number"
                  />
                  <mdb-input
                    label="Verpackungseinheit"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.packaging_unit"
                  />
                  <mdb-input
                    label="Mindestmenge"
                    type="text"
                    class="mb-3 mt-3"
                    v-model="model.minimum_order_quantity"
                  />
                </mdb-col>
              </mdb-row>
              <!--<mdb-row class="mt-4">
                <mdb-col md="6">
                  <legend><mdb-icon icon="truck" fas class="cyan-text" /> Lieferangaben</legend>
                </mdb-col>
                <mdb-col md="6">
                  <mdb-btn @click.native="showDeliveryNoteEditor = true" size="sm" outline="info" class="float-right"><mdb-icon icon="edit" fas/> Lieferanmerkung</mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col md="3">
                  <mdb-input
                    label="Muster"
                    type="text"
                    class="mb-3 mt-3"
                    disabled
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input
                    label="Min"
                    type="number"
                    class="mb-3 mt-3"
                    v-model="model.delivery_sample_min"
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input
                    label="Max"
                    type="number"
                    class="mb-3 mt-3"
                    v-model="model.delivery_sample_max"
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-select
                    label="Einheit"
                    class="mb-3 mt-3"
                    v-model="model.delivery_sample_unit"
                    ref="delivery_sample_unit"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row style="position: relative; top: -2px;">
                <mdb-col md="3">
                  <mdb-input
                    label="Freigabemuster"
                    type="text"
                    class="mb-0 mt-0"
                    disabled
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input
                    label="Min"
                    type="number"
                    class="mb-0 mt-0"
                    v-model="model.delivery_prebatch_min"
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input
                    label="Max"
                    type="number"
                    class="mb-0 mt-0"
                    v-model="model.delivery_prebatch_max"
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-select
                    label="Einheit"
                    class="mb-0 mt-0 custom2"
                    v-model="model.delivery_prebatch_unit"
                    ref="delivery_prebatch_unit"
                  />
                </mdb-col>
              </mdb-row>
              <mdb-row style="position: relative; top: -20px;">
                <mdb-col md="3">
                  <mdb-input
                    label="Serie"
                    type="text"
                    class="mb-0 mt-0"
                    disabled
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input
                    label="Min"
                    type="number"
                    class="mb-0 mt-0"
                    v-model="model.delivery_batch_min"
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-input
                    label="Max"
                    type="number"
                    class="mb-0 mt-0"
                    v-model="model.delivery_batch_max"
                  />
                </mdb-col>
                <mdb-col md="3">
                  <mdb-select
                    label="Einheit"
                    class="mb-0 mt-0 custom3"
                    v-model="model.delivery_batch_unit"
                    ref="delivery_batch_unit"
                  />
                </mdb-col>
              </mdb-row>-->
            </mdb-col>
            <mdb-col md="5" style="border-right: 1px solid #aaa;">
              <mdb-row>
                <mdb-col md="6">
                  <legend><mdb-icon icon="list-ol" class="cyan-text" /> Staffelmengen</legend>
                </mdb-col>
                <!--<mdb-col md="6">
                  <mdb-btn @click.native="showPriceNoteEditor = true" size="sm" outline="info" class="float-right"><mdb-icon icon="edit" fas/> Preisanmerkung</mdb-btn>
                </mdb-col>-->
              </mdb-row>
              <!--<mdb-input
                label="Preise gelten per x Stück"
                type="number"
                class="mb-3 mt-3"
                v-model="model.price_unit"
                required
              />-->
              <mdb-btn tag="a" color="info" floating size="sm" @click="priceTableAdd()"><mdb-icon icon="plus"/></mdb-btn>
              <b-editable-table
                bordered
                class="editable-table"
                v-model="model.price"
                :rowUpdate="priceTableRowUpdate"
                :editMode="'cell'"
                :fields="priceTableFields"
              >
                <template #cell(delete)="data">
                  <mdb-btn tag="a" color="red" floating size="sm" @click="priceTableDelete(data)"><mdb-icon icon="times"/></mdb-btn>
                </template>
              </b-editable-table>
              <legend class="mt-4"><mdb-icon icon="stamp" fas class="cyan-text" /> Nebenkosten</legend>
              <mdb-select
                label="Nebenkosten"
                v-model="model.finishing"
                ref="finishing"
                validation
                multiple
                selectAll
                search
              />
            </mdb-col>
            <mdb-col md="2">
              <legend><mdb-icon icon="images" class="cyan-text" /> Bilder</legend>
              <section>
                <mdb-row>
                  <mdb-col col="6" class="">
                    <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">1. Bild</h5>
                  </mdb-col>
                  <mdb-col col="6" class="">
                    <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_1 !== 'image_placeholder.svg'" @click="removeImage('image_1')">1. Bild entfernen</mdb-btn>
                  </mdb-col>
                </mdb-row> 
                <mdb-row class="p-3">
                  <img class="upload-image mb-3 img-fluid" :src="model.image_1">
                </mdb-row>
                <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                  <input id="image-1-upload" type="file" accept=".jpg, .jpeg, .png" @change="handleFileImage1( $event )"/>
                </mdb-row>
              </section>
              <section>
                <hr>
                <mdb-row>
                  <mdb-col col="6" class="">
                    <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">2. Bild</h5>
                  </mdb-col>
                  <mdb-col col="6" class="">
                    <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_2 !== 'image_placeholder.svg'" @click="removeImage('image_2')">2. Bild entfernen</mdb-btn>
                  </mdb-col>
                </mdb-row> 
                <mdb-row class="p-3">
                  <img class="upload-image mb-3 img-fluid" :src="model.image_2">
                </mdb-row>
                <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                  <input id="image-2-upload" type="file" accept=".jpg, .jpeg, .png" @change="handleFileImage2( $event )"/>
                </mdb-row>
              </section>
              <section>
                <hr>
                <mdb-row>
                  <mdb-col col="6" class="">
                    <h5 class="grey-text mb-0 mt-1 align-text-bottom h-100">3. Bild</h5>
                  </mdb-col>
                  <mdb-col col="6" class="">
                    <mdb-btn flat size="sm" darkWaves class="float-right p-2 red-text" v-show="model.image_3 !== 'image_placeholder.svg'" @click="removeImage('image_3')">3. Bild entfernen</mdb-btn>
                  </mdb-col>
                </mdb-row> 
                <mdb-row class="p-3">
                  <img class="upload-image mb-3 img-fluid" :src="model.image_3">
                </mdb-row>
                <mdb-row class="pl-3 pr-3 pt-0 pb-0">
                  <input id="image-3-upload" type="file" accept=".jpg, .jpeg, .png" @change="handleFileImage3( $event )"/>
                </mdb-row>
              </section>
              <!--<mdb-row>
                <mdb-col class="mt-4">
                  <div class="text-center">
                    <a v-bind:href="model.image_1" target="_blank"><img v-bind:src="model.image_1" class="product-image" /></a>
                  </div>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col class="mt-4 text-center">
                  <mdb-btn size="" outline="info" class="p-2"><mdb-icon icon="plus-square" fas/></mdb-btn>
                  <mdb-btn size="" outline="info" class="p-2"><mdb-icon icon="minus-square" fas/></mdb-btn>
                </mdb-col>
              </mdb-row>
              <mdb-row class="mt-4">
                <mdb-col class="mt-4">
                  <div class="text-center">
                    <a v-bind:href="model.image_2" target="_blank"><img v-bind:src="model.image_2" class="product-image" /></a>
                  </div>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col class="mt-4 text-center">
                  <mdb-btn size="" outline="info" class="p-2"><mdb-icon icon="plus-square" fas/></mdb-btn>
                  <mdb-btn size="" outline="info" class="p-2"><mdb-icon icon="minus-square" fas/></mdb-btn>
                </mdb-col>
              </mdb-row>-->
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click.native="closeModal">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" v-if="modalEdit">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Speichern
        </mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" v-else>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Hinzufügen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Product -->
    <!-- Modal: Editor -->
    <mdb-modal
      centered
      removeBackdrop
      size="lg"
      direction="bottom"
      :show="showDescriptionEditor"
      @close="closeEditor"
    >
      <mdb-modal-header>
        <mdb-modal-title>Produktbeschreibung bearbeiten</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col md="12" class="mb-3">
            <mdb-row>
              <mdb-col class="mb-3">
                <vue-editor
                  id="descriptionEditor"
                  v-model="model.description"
                  :editor-toolbar="editorToolbar"
                />
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click.native="showDescriptionEditor = false">Schließen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Editor -->
    <!-- Modal: Editor -->
    <mdb-modal
      centered
      removeBackdrop
      size="lg"
      direction="bottom"
      :show="showDeliveryNoteEditor"
      @close="closeEditor"
    >
      <mdb-modal-header>
        <mdb-modal-title>Lieferanmerkung bearbeiten</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col md="12" class="mb-3">
            <mdb-row>
              <mdb-col class="mb-3">
                <vue-editor
                  id="deliveryNoteEditor"
                  v-model="model.delivery_note"
                  :editor-toolbar="editorToolbar"
                />
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click.native="showDeliveryNoteEditor = false">Schließen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Editor -->
    <!-- Modal: Editor -->
    <mdb-modal
      centered
      removeBackdrop
      size="lg"
      direction="bottom"
      :show="showPriceNoteEditor"
      @close="closeEditor"
    >
      <mdb-modal-header>
        <mdb-modal-title>Preisanmerkung bearbeiten</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col md="12" class="mb-3">
            <mdb-row>
              <mdb-col class="mb-3">
                <vue-editor
                  id="priceNoteEditor"
                  v-model="model.price_note"
                  :editor-toolbar="editorToolbar"
                />
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn outline="info" @click.native="showPriceNoteEditor = false">Schließen</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Editor -->
    <!-- Modal: Delete -->
    <mdb-modal :show="showDelete" @close="showDelete = false" danger>
      <mdb-modal-header>
        <p class="heading"><strong>Produkt löschen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="ban" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Produkte wirklich löschen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.label">
                      {{ record.label }}
                    </li>
                    <br>
                    <p v-show="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" @click="showDelete = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="danger" :disabled="loading === true" @click.native="deleteEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Löschen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Delete -->
    <!-- Modal: Categorize -->
    <mdb-modal :show="showCategorize" @close="showCategorize = false">
      <mdb-modal-header>
        <p class="heading"><strong>Produktlinie einstellen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die gewählten Produkte mit folgender Linie verknüpfen?</strong>
            </p>
            <mdb-card>
              <mdb-card-body class="pb-0">
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-select
                    label="Produktlinie"
                    class="mt-3"
                    v-model="model.category_1"
                    ref="bulk_category_1"
                    search
                  />
                  <!--<mdb-select
                    label="2. Kategorie"
                    class="mt-5"
                    style="position: relative; top: -8px;"
                    v-model="model.category_2"
                    ref="bulk_category_2"
                    search
                  />-->
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.label">
                      {{ record.label }}
                    </li>
                    <br>
                    <p v-show="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="info" @click="showCategorize = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" @click.native="categorizeEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Verknüpfen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Categorize -->
    <!-- Modal: Publish -->
    <mdb-modal :show="showPublish" @close="showPublish = false">
      <mdb-modal-header>
        <p class="heading"><strong>Produkt veröffentlichen</strong></p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3" class="mb-3">
            <mdb-icon icon="tag" size="4x" class="grey-text"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              <strong>Wollen Sie die Veröffentlichung der gewählten Produkte ändern?</strong>
            </p>
            <mdb-card>
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <mdb-input class="pl-0" type="radio" id="publish-type-1" name="publish" radioValue="yes" v-model="model.published" label="Veröffentlichen" />
                  <mdb-input class="pl-0" type="radio" id="publish-type-2" name="publish" radioValue="no" v-model="model.published" label="Nicht veröffentlichen" />
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <ul>
                    <li v-for="(record) in selectedRecords.slice(0, 5)" :key="record.label">
                      {{ record.label }}
                    </li>
                    <br>
                    <p v-show="selectedRecords.length > 5">... und <b>{{ selectedRecords.length - 5 }}</b> weitere.</p>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="info" @click="showPublish = false">Abbrechen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" @click.native="publishEntry">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Ändern
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: Publish -->
    <!-- Modal: importRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showImportRecords"
      @close="showImportRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Produktdaten importieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-import" fas size="4x" class="cyan-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie eine Datei (Excel oder CSV) aus die importiert werden soll:
            </p>
            <input type="file" @change="handleFileUpload( $event )"/>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="info" @click="showImportRecords = false">Abbrechen</mdb-btn>
        <!--<mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" @click.native="importRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Importieren
        </mdb-btn>-->
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="true" @click.native="importRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Importieren
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: importRecords -->
    <!-- Modal: exportRecords -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showExportRecords"
      @close="showExportRecords = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Datenblatt erzeugen</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-import" fas size="4x" class="cyan-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Bitte wählen Sie die gewünschten Optionen für die Datenblatt-Erstellung:
            </p>
            <mdb-row>
              <!--<mdb-col col="4">
                <legend>Sprache</legend>
                <mdb-input type="radio" id="language-option-1" name="languageOptions" radioValue="de" v-model="action.language" class="pl-0 mb-2" label="Deutsch" checked />
                <mdb-input type="radio" id="language-option-2" name="languageOptions" radioValue="en" v-model="action.language" class="pl-0 mb-2" label="Englisch" />
                <mdb-input type="radio" id="language-option-3" name="languageOptions" radioValue="fr" v-model="action.language" class="pl-0 mb-2" label="Französisch" />
              </mdb-col>-->
              <mdb-col col="12">
                <legend>Rabatt</legend>
                <mdb-input type="radio" id="discount-option-1" name="discountOptions" radioValue="0" v-model="action.discount" class="pl-0 mb-2" label="Nur Industriepreis" checked />
                <mdb-input type="radio" id="discount-option-2" name="discountOptions" radioValue="0.25" v-model="action.discount" class="pl-0 mb-2" label="25,0 %" />
                <mdb-input type="radio" id="discount-option-3" name="discountOptions" radioValue="0.30" v-model="action.discount" class="pl-0 mb-2" label="30,0 %" />
                <mdb-input type="radio" id="discount-option-4" name="discountOptions" radioValue="0.32" v-model="action.discount" class="pl-0 mb-2" label="32,0 %" />
                <mdb-input type="radio" id="discount-option-5" name="discountOptions" radioValue="0.333" v-model="action.discount" class="pl-0 mb-2" label="33,3 %" />
              </mdb-col>
            </mdb-row>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title></mdb-card-title>
                <mdb-card-text>
                  <div class="mb-2 pl-4">Ausgewählte Produkte:</div>
                  <p class="pl-4">{{ selectedRecords.length }} Produkt(e)</p>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="info" @click="showExportRecords = false">Abbrechen</mdb-btn>
        <!--<mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" @click.native="exportRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Erstellen
        </mdb-btn>-->
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true" @click.native="exportRecords">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Erstellen
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: exportRecords -->
    <!-- Modal: importImages -->
    <mdb-modal
      centered
      size="lg"
      scrollable
      removeBackdrop
      :show="showImportImages"
      @close="showImportImages = false"
    >
      <mdb-modal-header color="cloudy-knoxville-gradient">
        <mdb-modal-title>Produktbilder importieren</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="2" class="mb-3">
            <mdb-icon icon="file-import" fas size="4x" class="cyan-text ml-4"/>
          </mdb-col>
          <mdb-col col="9" class="mb-2">
            <p class="mb-4">
              Wählen sie Bilddateien aus die importiert werden soll:
            </p>
            <input type="file" accept=".jpg, .jpeg, .png" ref="batchUpload" @change="handleBatchUpload( $event )" multiple/>
            <mdb-card class="mt-4">
              <mdb-card-body>
                <mdb-card-title>Warteschlange</mdb-card-title>
                <mdb-card-text>
                  <mdb-progress :value="this.importImagesProgress" :height="20" />
                  <p v-show="this.importImagesTotal > 0">{{ importImagesUploaded }} von {{ importImagesTotal }} Bildern hochgeladen</p>
                  <p v-show="this.importImagesComplete === true" class="green-text">Import abgeschlossen, Sie können den Dialog jetzt schließen</p>
                  <p v-show="this.importImagesFailed === 1" class="red-text">{{ importImagesFailed }} Bild konnte nicht zugeordnet werden</p>
                  <p v-show="this.importImagesFailed > 1" class="red-text">{{ importImagesFailed }} Bilder konnten nicht zugeordnet werden</p>
                  <ul v-show="this.importImagesFailed > 0" class="red-text">
                    <li v-for="failed in this.importImagesFailedList" :key="failed">
                      {{ failed }}
                    </li>
                  </ul>
                </mdb-card-text>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="info" @click="showImportImages = false">Schließen</mdb-btn>
        <mdb-btn type="submit" form="modal-form" color="info" :disabled="loading === true || importImagesTotal === 0 || importImagesComplete === true" @click.native="importImages">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="loading === true"></span>
          Import Starten
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal: importImages -->
  </mdb-container>
</template>

<script>
function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges()
  } else if (document.selection) {
    document.selection.empty()
  }
}

import Vue from 'vue'
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as cagregorian from 'cldr-data/main/de/ca-gregorian.json'
import * as numbers from 'cldr-data/main/de/numbers.json'
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencies from 'cldr-data/main/de/currencies.json'
import { L10n, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base'
import { GridPlugin, Resize, Toolbar, Sort, Search, Edit } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import axios from 'axios'
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbIcon,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText,
  mdbSelect,
  mdbProgress
} from 'mdbvue'
import { VueEditor } from "vue2-editor"
import BEditableTable from 'bootstrap-vue-editable-table'

L10n.load({
  'de': {
    'grid': {
      'EmptyRecord': 'Keine Einträge gefunden',
      'GroupDropArea': 'Spalte hier ablegen um zu gruppieren',
      'UnGroup': 'Klicken Sie hier, um die Gruppierung aufheben',
      'Item': 'Eintrag',
      'Items': 'Einträge',
      'Search': 'Suche',
      'FilterButton': 'Filtern',
      'ClearButton': 'Löschen',
      'SelectAll': 'Alle',
      'Blanks': 'Leer',
      'Add': 'Hinzufügen',
      'Edit': 'Bearbeiten',
      'Delete': 'Löschen',
      'Enter the value': 'Suchbegriff eingeben'
    },
    'pager': {
      'currentPageInfo': '{0} von {1} Seiten',
      'totalItemsInfo': '({0} Einträge)',
      'firstPageTooltip': 'Zur ersten Seite',
      'lastPageTooltip': 'Zur letzten Seite',
      'nextPageTooltip': 'Zur nächsten Seite',
      'previousPageTooltip': 'Zurück zur letzten Seit',
      'nextPagerTooltip': 'Zum nächsten Pager',
      'previousPagerTooltip': 'Zum vorherigen Pager'
    }
  }
})
loadCldr(numberingSystems, cagregorian, numbers, timeZoneNames, weekData, currencies)
setCulture('de')
setCurrencyCode('EUR')

Vue.use(GridPlugin)

export default {
  name: 'Products',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbSelect,
    mdbProgress,
    VueEditor,
    BEditableTable
  },
  data () {
    return {
      loading: false,
      model: {
        id: 'new',
        published: 'no',
        category_1: [],
        category_2: [],
        item_root: '',
        item_number: '',
        item_variant: '',
        label: '',
        description: '',
        dimensions: '',
        weight: '',
        material: '',
        max_advertising_space: '',
        catalog_page: '',
        country_of_origin: '',
        customs_tariff_number: '',
        packaging_unit: '',
        minimum_order_quantity: '',
        delivery_note: '',
        delivery_sample_min: 0,
        delivery_sample_max: 0,
        delivery_sample_unit: [
          { text: 'Werktage', value: 'Werktage', selected: true },
          { text: 'Arbeitstage', value: 'Arbeitstage', selected: false },
          { text: 'Tage', value: 'Tage', selected: false },
          { text: 'Wochen', value: 'Wochen', selected: false },
          { text: 'Monate', value: 'Monate', selected: false }
        ],
        delivery_prebatch_min: 0,
        delivery_prebatch_max: 0,
        delivery_prebatch_unit: [
          { text: 'Werktage', value: 'Werktage', selected: true },
          { text: 'Arbeitstage', value: 'Arbeitstage', selected: false },
          { text: 'Tage', value: 'Tage', selected: false },
          { text: 'Wochen', value: 'Wochen', selected: false },
          { text: 'Monate', value: 'Monate', selected: false }
        ],
        delivery_batch_min: 0,
        delivery_batch_max: 0,
        delivery_batch_unit: [
          { text: 'Werktage', value: 'Werktage', selected: true },
          { text: 'Arbeitstage', value: 'Arbeitstage', selected: false },
          { text: 'Tage', value: 'Tage', selected: false },
          { text: 'Wochen', value: 'Wochen', selected: false },
          { text: 'Monate', value: 'Monate', selected: false }
        ],
        finishing: [],
        price_note: '',
        price_unit: 1,
        price: [],
        image_1: 'image_placeholder.svg',
        image_2: 'image_placeholder.svg',
        image_3: 'image_placeholder.svg'
      },
      files: {
        image_1: [],
        image_2: [],
        image_3: []
      },
      batch: [],
      action: {
        selection: [],
        category: 'products',
        import: 'gww',
        export: 'all',
        discount: 0
      },
      priceTableFields: [
        { key: 'quantity', label: 'Staffelmenge', type: 'text', editable: true, placeholder: '', class: 'quarter-col price-table-input' },
        { key: 'price_industry', label: 'Industriepreis', type: 'text', editable: true, placeholder: '', class: 'quarter-col price-table-input' },
        { key: 'price_trade', label: 'Handelspreis', type: 'text', editable: true, placeholder: '', class: 'quarter-col price-table-input' },
        { key: 'delete', label: '', class: 'quarter-col' }
      ],
      priceTableRowUpdate: {},
      selectedRecords: [],
      exportRecordCount: 0,
      importImagesTotal: 0,
      importImagesUploaded: 0,
      importImagesFailed: 0,
      importImagesFailedList: [],
      importImagesComplete: false,
      showModal: false,
      showDescriptionEditor: false,
      showDeliveryNoteEditor: false,
      showPriceNoteEditor: false,
      modalEdit: false,
      showDelete: false,
      showCategorize: false,
      showPublish: false,
      showImportRecords: false,
      showExportRecords: false,
      showImportImages: false,
      deleteList: [],
      wasValidated: false,
      dataSource: new DataManager({
        url: Vue.prototype.$serverUrl + 'api/list_products.php',
        adaptor: new UrlAdaptor,
        crossDomain: true
      }),
      toolbar: [
        { text: 'Hinzufügen', tooltipText: 'Produkt hinzufügen', prefixIcon: 'e-add', id: 'addRecord' },
        { text: 'Bearbeiten', tooltipText: 'Produkt bearbeiten', prefixIcon: 'e-edit', id: 'editRecord' },
        { text: 'Löschen', tooltipText: 'Produkte löschen', prefixIcon: 'e-delete-4', id: 'deleteRecord' },
        { type: 'separator' },
        { text: 'Produktlinie', tooltipText: 'Produkten eine Linie zuweisen', prefixIcon: 'e-bookmark', id: 'categorizeRecord' },
        // { type: 'separator' },
        // { text: 'Veröffentlichen', tooltipText: 'Produkte veröffentlichen', prefixIcon: 'e-eye', id: 'publishRecord' },
        { type: 'separator' },
        { text: 'Import', tooltipText: 'Produkte importieren', prefixIcon: 'e-icon-upload', id: 'importRecords' },
        { text: 'Bilderupload', tooltipText: 'Mehrere Produktbilder hochladen', prefixIcon: 'e-image', id: 'importImages' },
        { type: 'separator' },
        { text: 'Datenblatt erzeugen', tooltipText: 'Datenblätter erzeugen', prefixIcon: 'e-icon-export', id: 'exportRecords' },
        'Search'
      ],
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      sortSettings: {
        columns: [{
          field: 'item_number',
          direction: 'Ascending'
        }]
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        allowEditOnDblClick: false
      },
      selectionOptions: {
        checkboxMode: 'Default'
      },
      labelColumnAttributes : {
        class: 'label-column'
      }
    }
  },
  mounted: function() {
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('keyup', () => {
      this.$refs.grid.search((event.target).value)
    });
    document.getElementById(this.$refs.grid.ej2Instances.element.id + '_searchbar').addEventListener('click', () => {
      this.$refs.grid.ej2Instances.searchSettings.key = ''
    });
    this.resetModel()
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['categorizeRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['publishRecord'], false)
    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['exportRecords'], false)
  },
  computed: {
    importImagesProgress() {
      if (this.importImagesTotal !== 0) {
        return (this.importImagesUploaded / this.importImagesTotal) * 100
      } else {
        return 0
      }
    }
  },
  watch: {
    selectedRecords: {
      handler: function () {
        if (this.selectedRecords.length === 0) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['categorizeRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['publishRecord'], false)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['exportRecords'], false)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['deleteRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['categorizeRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['publishRecord'], true)
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['exportRecords'], true)
        }
        if (this.selectedRecords.length === 1) {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], true)
        } else {
          this.$refs.grid.ej2Instances.toolbarModule.enableItems(['editRecord'], false)
        }
      },
      deep: true
    },
    showImportImages: {
      handler: function () {
        if (this.showImportImages === false) {
          this.batch = []
          this.importImagesTotal = 0
          this.importImagesUploaded = 0
          this.importImagesFailed = 0
          this.importImagesFailedList = []
          this.importImagesComplete = false
        }
      }
    }
  },
  methods: {
    dataBound: function () {
      this.$refs.grid.autoFitColumns(['category_1', 'category_2', 'item_root', 'item_number', 'item_variant', 'label', 'description', 'image', 'finishing', 'published', 'modified'])
    },
    queryCellInfo: function (args) {
      if (args.column.field === 'image') {
        if (args.data['image'] === true) {
          args.cell.classList.add('status-circle', 'status-green')
        } else {
          args.cell.classList.add('status-circle', 'status-red')
        }
        args.cell.textContent = ''
      }

      if (args.column.field === 'finishing') {
        if (args.data['finishing'] === true) {
          args.cell.classList.add('status-circle', 'status-green')
        } else {
          args.cell.classList.add('status-circle', 'status-red')
        }
        args.cell.textContent = ''
      }

      if (args.column.field === 'published') {
        if (args.data['published'] === true) {
          args.cell.classList.add('status-circle', 'status-green')
        } else {
          args.cell.classList.add('status-circle', 'status-red')
        }
        args.cell.textContent = ''
      }
    },
    clickToolbar: function (args) {
      if (args.item.id === 'addRecord') {
        if (this.selectedRecords.length > 0) {
          axios.get('get_product.php?id=' + this.selectedRecords[0].id).then(response => {
            this.model = response.data
            this.model.item_variant = ''
            this.showModal = true
            this.modalEdit = false
          })
        } else {
          this.showModal = true
          this.modalEdit = false
        }
      }
      if (args.item.id === 'editRecord') {
        axios.get('get_product.php?id=' + this.selectedRecords[0].id).then(response => {
          this.model = response.data
          this.showModal = true
          this.modalEdit = true
        })
      }
      if (args.item.id === 'deleteRecord') {
        this.deleteList = []
        for (var i = 0; i < this.selectedRecords.length; i++) {
          this.deleteList.push(this.selectedRecords[i].id)
        }
        this.showDelete = true
      }
      if (args.item.id === 'categorizeRecord') {
        this.deleteList = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.deleteList.push(this.selectedRecords[i].id)
        }
        this.showCategorize = true
      }
      if (args.item.id === 'publishRecord') {
        this.deleteList = []
        for (i = 0; i < this.selectedRecords.length; i++) {
          this.deleteList.push(this.selectedRecords[i].id)
        }
        this.showPublish = true
      }
      if (args.item.id === 'importRecords') {
        this.showImportRecords = true
      }
      if (args.item.id === 'exportRecords') {
        this.showExportRecords = true
      }
      if (args.item.id === 'importImages') {
        this.showImportImages = true
      }
    },
    onRowSelected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    onRowDeselected: function() {
      this.selectedRecords = this.$refs.grid.getSelectedRecords()
    },
    recordDoubleClick: function(row) {
      clearSelection()
      this.$refs.grid.clearRowSelection()
      this.$refs.grid.selectRow(row.rowIndex)
      axios.get('get_product.php?id=' + row.rowData.id).then(response => {
        this.model = response.data
        this.showModal = true
        this.modalEdit = true
      })
    },
    priceTableAdd() {
      this.priceTableRowUpdate = {
        edit: true,
        id: Date.now(),
        action: 'add',
        data: {
          id: Date.now(),
          quantity: '',
          price_industry: '',
          price_trade: ''
        }
      }
    },
    priceTableDelete(data) {
      this.priceTableRowUpdate = { id: data.id, action: 'delete' }
    },
    closeModal () {
      this.showModal = false
      this.showDescriptionEditor = false,
      this.showDeliveryNoteEditor = false,
      this.showPriceNoteEditor = false,
      this.resetModel()
      //this.$refs.grid.clearRowSelection()
    },
    closeEditor () {
      this.showDescriptionEditor = false,
      this.showDeliveryNoteEditor = false,
      this.showPriceNoteEditor = false
    },
    handleFileImage1( event ){
      this.files.image_1 = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.image_1)
      reader.onload = e =>{
          this.model.image_1 = e.target.result
      }
    },
    handleFileImage2( event ){
      this.files.image_2 = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.image_2)
      reader.onload = e =>{
          this.model.image_2 = e.target.result
      }
    },
    handleFileImage3( event ){
      this.files.image_3 = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.files.image_3)
      reader.onload = e =>{
          this.model.image_3 = e.target.result
      }
    },
    removeImage (type) {
      if (type === 'image_1') {
        this.model.image_1 = 'image_placeholder.svg'
        this.files.image_1 = []
      }
      if (type === 'image_2') {
        this.model.image_2 = 'image_placeholder.svg' 
        this.files.image_2 = []
      }
      if (type === 'image_3') {
        this.model.image_3 = 'image_placeholder.svg' 
        this.files.image_3 = []
      }
    },
    saveModal(event) {
      this.wasValidated = true
      event.target.classList.add('was-validated')
      if (this.model.item_number !== '' && this.model.label !== '') {
        this.loading = true
        let formData = new FormData()
        formData.append('product_id', this.model.id)
        formData.append('image_1', this.files.image_1)
        if (this.model.image_1 === 'image_placeholder.svg') {
          formData.append('delete_image_1', true)
        } else {
          formData.append('delete_image_1', false)
        }
        formData.append('image_2', this.files.image_2)
        if (this.model.image_2 === 'image_placeholder.svg') {
          formData.append('delete_image_2', true)
        } else {
          formData.append('delete_image_2', false)
        }
        formData.append('image_3', this.files.image_3)
        if (this.model.image_3 === 'image_placeholder.svg') {
          formData.append('delete_image_3', true)
        } else {
          formData.append('delete_image_3', false)
        }
        axios.post( 'import_images.php',
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
          if (response.data.success === true) {
            this.files.image_1 = []
            this.files.image_2 = []
            this.files.image_3 = []
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })

        var url = ''
        if (this.modalEdit === true) {
          url = 'edit_product.php'
        } else {
          url = 'add_product.php'
        }
        axios({
          method: 'post',
          url: url,
          data: this.model
        }).then(response => {
          this.loading = false
          if (response.data.success === false) {
            alert(response.data.error)
          } else {
            if (this.modalEdit === true) {
              var rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(this.model.id)
              this.$refs.grid.updateRow(rowIndex, this.model)
            } else {
              this.$refs.grid.refresh()
            }
            this.showModal = false
            this.resetModel()
            //this.$refs.grid.clearRowSelection()
          }
        }).catch(error => {
          this.loading = false
          alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
        })
      }
    },
    deleteEntry () {
      this.loading = true
      var url = 'delete_product.php'
      axios({
        method: 'post',
        url: url,
        data: this.deleteList
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showDelete = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    categorizeEntry () {
      this.loading = true
      var url = 'categorize_product.php'
      const payload = {
        ids: this.deleteList,
        category_1: this.model.category_1,
        category_2: this.model.category_2
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showCategorize = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    publishEntry () {
      this.loading = true
      var url = 'publish_product.php'
      const payload = {
        ids: this.deleteList,
        published: this.model.published
      }
      axios({
        method: 'post',
        url: url,
        data: payload
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showPublish = false
          this.resetModel()
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    handleFileUpload( event ) {
      this.file = event.target.files[0]
    },
    importRecords () {
      this.loading = true
      let formData = new FormData()
      formData.append('file', this.file)
      axios.post( 'import_products.php?action=' + this.action.import,
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          alert(response.data.error)
        } else {
          this.$refs.grid.refresh()
          this.showImportRecords = false
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    exportRecords () {
      var url = Vue.prototype.$serverUrl + 'api/export_products.php?discount=' + this.action.discount + '&id='  + this.selectedRecords[0].id
      window.open(url)
    },
    handleBatchUpload( event ){
      this.batch = event.target.files
      this.importImagesTotal = this.batch.length
      this.importImagesUploaded = 0
      this.importImagesFailed = 0
      this.importImagesFailedList = []
      this.importImagesComplete = false
    },
    async importImages () {
      this.loading = true
      for (const image of this.batch) {
        await this.importImageBatch(image)
      }
      this.importImagesComplete = true
      this.loading = false
      this.$refs.grid.refresh()
    },
    async importImageBatch (image) {
      let formData = new FormData()
      formData.append('image', image)
      formData.append('type', 'batch')
      await axios.post(
        'import_images.php',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        if (response.data.success === false) {
          this.importImagesFailed = this.importImagesFailed + 1
          this.importImagesFailedList.push(response.data.failed)
          this.importImagesUploaded = this.importImagesUploaded + 1
        } else {
          this.importImagesUploaded = this.importImagesUploaded + 1
        }
      }).catch(error => {
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    resetModel () {
      this.wasValidated = false
      this.model.id = 'new'
      this.model.published = 'no'
      this.model.category_1 = []
      axios.get('list_categories.php').then(response => {
        this.model.category_1 = response.data
      })
      this.model.category_2 = []
      axios.get('list_categories.php').then(response => {
        this.model.category_2 = response.data
      })
      this.model.item_root = ''
      this.model.item_number = ''
      this.model.item_variant = ''
      this.model.label = ''
      this.model.dimensions = ''
      this.model.weight = ''
      this.model.material = ''
      this.model.max_advertising_space = ''
      this.model.catalog_page = ''
      this.model.country_of_origin = ''
      this.model.customs_tariff_number = ''
      this.model.packaging_unit = ''
      this.model.minimum_order_quantity = ''
      this.model.description = ''
      this.model.delivery_note = ''
      this.model.delivery_sample_min = 0
      this.model.delivery_sample_max = 0
      this.model.delivery_sample_unit = [
        { text: 'Werktage', value: 'Werktage', selected: true },
        { text: 'Arbeitstage', value: 'Arbeitstage', selected: false },
        { text: 'Tage', value: 'Tage', selected: false },
        { text: 'Wochen', value: 'Wochen', selected: false },
        { text: 'Monate', value: 'Monate', selected: false }
      ]
      this.model.delivery_prebatch_min = 0
      this.model.delivery_prebatch_max = 0
      this.model.delivery_prebatch_unit = [
        { text: 'Werktage', value: 'Werktage', selected: true },
        { text: 'Arbeitstage', value: 'Arbeitstage', selected: false },
        { text: 'Tage', value: 'Tage', selected: false },
        { text: 'Wochen', value: 'Wochen', selected: false },
        { text: 'Monate', value: 'Monate', selected: false }
      ]
      this.model.delivery_batch_min = 0
      this.model.delivery_batch_max = 0
      this.model.delivery_batch_unit = [
        { text: 'Werktage', value: 'Werktage', selected: true },
        { text: 'Arbeitstage', value: 'Arbeitstage', selected: false },
        { text: 'Tage', value: 'Tage', selected: false },
        { text: 'Wochen', value: 'Wochen', selected: false },
        { text: 'Monate', value: 'Monate', selected: false }
      ]
      this.model.finishing = []
      axios.get('list_finishings.php').then(response => {
        var self = this
        response.data.result.forEach(function(finishing) {
          var row = { text: '(' + finishing.shorttag + ') ' + finishing.label, value: finishing.id, selected: false }
          self.model.finishing.push(row);
        })
      })
      this.model.price_note = ''
      this.model.price_unit = 1
      this.model.price = []
      this.model.image_1 = 'image_placeholder.svg'
      this.model.image_2 = 'image_placeholder.svg'
      this.model.image_3 = 'image_placeholder.svg'
    }
  },
  provide: {
    grid: [Toolbar, Search, Resize, Sort, Edit]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.e-grid .e-rowcell.label-column {
  font-weight: normal;
  font-size: 16px;
}

.padding-label label {
  margin-bottom: 0px;
  margin-top: 14px;
}

.select-wrapper {
  position: relative;
  top: -8px;
}

.custom2 .select-wrapper {
  top: -24px;
}

.custom3 .select-wrapper {
  top: -24px;
}

.row .col-md-3 {
  height: 75px !important;
}

.price-data .table-add {
  float: left !important;
  font-size: 14px;
  margin-bottom: 0px !important;
}

.price-data tr td:nth-child(4), .price-data tr th:nth-child(4) {
  display: none;
}

.product-image {
  width: auto;
  max-width: 200px;
  max-height: 150px;
}

.image-column {
  max-width: 200px;
}

#descriptionEditor, #deliveryNoteEditor, #priceNoteEditor {
  height: 400px;
}

.modal-without-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: initial !important;
}

table.editable-table {
  margin: auto;
  width: 100% !important;
}

table.editable-table td {
  vertical-align: middle;
}

.editable-table .data-cell {
  padding: 8px;
  vertical-align: middle;
}

table.editable-table .quarter-col {
  
}

table.editable-table .half-col {
  
}
</style>
